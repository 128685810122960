<template>
  <div id="app">

    <div class="wrapper">

      <div class="tetris">
        <div class="tetris__wrapper" ref="printMe">
          <img class="tetrisImg" :src="'/img/tetris/label-i.png'" width="222" height="216" alt="Label Access-i">
          <!-- Fauteuil roulant -->
          <img class="tetrisImg" :src="`/img/tetris/wheelchair_${form.wheelchair}.png`" width="222" height="216" alt="Accessible avec un coup de main ponctuel aux personnes en fauteuil roulant">
          <!-- Marchant difficilement -->
          <img class="tetrisImg" :src="`/img/tetris/walking_${form.walking}.png`" width="222" height="216" alt="Accessible avec un coup de main ponctuel aux personnes marchant difficilement">
          <!-- Aveugle -->
          <img class="tetrisImg" :src="`/img/tetris/blind_${form.blind}.png`" width="222" height="216" alt="Accessible avec un coup de main ponctuel aux personnes aveugles">
          <!-- Lowvision -->
          <img class="tetrisImg" :src="`/img/tetris/lowvision_${form.lowvision}.png`" width="222" height="216" alt="Une fiche informative est disponible pour l’accessibilité pour les personnes malvoyantes">
          <!-- deaf -->
          <img class="tetrisImg" :src="`/img/tetris/deaf_${form.deaf}.png`"  width="222" height="216" alt="Accessible avec un coup de main ponctuel aux personnes sourdes">
          <!-- harthearing -->
          <img class="tetrisImg" :src="`/img/tetris/harthearing_${form.harthearing}.png`" width="222" height="216" alt="Accessible avec un coup de main ponctuel aux personnes malentendantes">
          <!-- comprehension -->
          <img class="tetrisImg" :src="`/img/tetris/comprehension_${form.comprehension}.png`" width="222" height="216" alt="Une fiche informative est disponible pour l’accessibilité pour les personnes avec difficultés de compréhension">
        </div>
      </div>


      <div style="margin-top: 20px;">
        <el-form ref="form" :model="form" label-width="200px" label-position="left">
          <!-- Compréhension -->
          <el-form-item label="Difficultés de compréhension">
            <el-radio-group v-model="form.comprehension">
              <el-radio :label="0">Blanc</el-radio>
              <el-radio :label="1">Orange</el-radio>
              <el-radio :label="2">Vert</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- wheelchair -->
          <el-form-item label="Fauteuil roulant">
            <el-radio-group v-model="form.wheelchair">
              <el-radio :label="0">Blanc</el-radio>
              <el-radio :label="1">Orange</el-radio>
              <el-radio :label="2">Vert</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- Marchant difficilement -->
          <el-form-item label="Marchant difficilement">
            <el-radio-group v-model="form.walking">
              <el-radio :label="0">Blanc</el-radio>
              <el-radio :label="1">Orange</el-radio>
              <el-radio :label="2">Vert</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- Aveugle -->
          <el-form-item label="Aveugle">
            <el-radio-group v-model="form.blind">
              <el-radio :label="0">Blanc</el-radio>
              <el-radio :label="1">Orange</el-radio>
              <el-radio :label="2">Vert</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- Mal voyant -->
          <el-form-item label="Mal voyant">
            <el-radio-group v-model="form.lowvision">
              <el-radio :label="0">Blanc</el-radio>
              <el-radio :label="1">Orange</el-radio>
              <el-radio :label="2">Vert</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- Sourd -->
          <el-form-item label="Sourd">
            <el-radio-group v-model="form.deaf">
              <el-radio :label="0">Blanc</el-radio>
              <el-radio :label="1">Orange</el-radio>
              <el-radio :label="2">Vert</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- Malentendant -->
          <el-form-item label="Malentendant">
            <el-radio-group v-model="form.harthearing">
              <el-radio :label="0">Blanc</el-radio>
              <el-radio :label="1">Orange</el-radio>
              <el-radio :label="2">Vert</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>

        <div style="text-align:center; margin-top: 25px;">
          <el-button type="primary" @click.prevent="saveImg">Exporter l’image</el-button>
        </div>

      </div>

    </div>


  </div>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      form: {
        comprehension: 0,
        wheelchair: 0,
        walking: 0,
        blind: 0,
        lowvision: 0,
        deaf: 0,
        harthearing: 0
      }
    }
  },

  methods: {
    async saveImg() {
      const el = this.$refs.printMe;
      let options = { type: "dataURL", backgroundColor: null };
      const printCanvas = await this.$html2canvas(el, options);

      const a = document.createElement("a");
      a.href = printCanvas;
      a.download = "access-i-cube.png";
      a.click();
    }
  },

  components: {
    //
  }
}
</script>

<style>
.wrapper {
  max-width: 430px;
  margin: 50px auto 0 auto;
}

.el-form-item {
  margin-bottom: 0;
}

.tetris__wrapper {
  background-color: transparent;
  margin: 0 auto;
  position: relative;
  width: 13.875em;
  height: 13.5em;
  box-sizing: content-box;
}

.tetris__wrapper .tetrisImg {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
